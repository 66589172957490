import env from '../env'
import { msalInstance, scopes } from '../authConfig'

export default class ApiRequest {
  async _getAccessToken () {
    const accounts = msalInstance.getAllAccounts()

    const account = accounts[0]

    const request = {
      account,
      scopes
    }

    let token

    try {
      token = await msalInstance.acquireTokenSilent(request)
    } catch (err) {
      // User does not have active session error
      if (err.message.includes('AADB2C90077')) {
        msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' })
        console.log('User token timed out')
      }

      console.error('Failed to aquire token: ', err)
    }

    const accessToken = token.accessToken

    return accessToken
  }

  _generateUrl (path) {
    return new URL(env.REACT_APP_API_ENDPOINT + path)
  }

  _generateHeaders (accessToken) {
    const bearer = `Bearer ${accessToken}`

    const headers = new Headers()
    headers.append('Authorization', bearer)
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')

    return headers
  }

  async _processResponse (response) {
    if (response.status === 200) {
      return await response.json()
    } else {
      const status = response.status
      const message = await response.text()

      throw new Error(`API Request Failed: ${status} / ${message}`)
    }
  }

  async analysisIndex (page, perPage, service) {
    const url = this._generateUrl(
      `/analyses?page=${page}&per_page=${perPage}&service=${service}`
    )

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'GET',
      headers
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async evaluationIndex (page, perPage, service) {
    const url = this._generateUrl(
      `/evaluation/analyses?page=${page}&per_page=${perPage}&service=${service}`
    )

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'GET',
      headers
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async analysisDownload (analysisId) {
    let url

    try {
      url = this._generateUrl(`/analyses/${analysisId}/download`)
    } catch (error) {
      console.error('FAILED TO GENERATE URL: ', error)
    }

    const accessToken = await this._getAccessToken()
    const headers = this._generateHeaders(accessToken)
    const options = {
      method: 'GET',
      headers
    }

    const response = await fetch(url, options)
    return await this._processResponse(response)
  }

  async analysisHistory (analysisId) {
    const url = this._generateUrl(`/analyses/${analysisId}/history`)

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'GET',
      headers
    }
    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async recordLogin () {
    const url = this._generateUrl('/record/login')

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        token: accessToken
      })
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async createDraft () {
    const url = this._generateUrl('/evaluation/create/draft')

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'POST',
      headers
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async studyFileUpload (studyId, studyfileName) {
    const url = this._generateUrl('/evaluation/create/studyfile-upload')

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        study_id: studyId,
        studyfile_name: studyfileName
      })
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }

  async submitStudy (
    studyId,
    studyFileUrls,
    institutionName,
    name,
    selectedProduct,
    reasonForEvaluation
  ) {
    const url = this._generateUrl('/evaluation/create/submit')

    const accessToken = await this._getAccessToken()

    const headers = this._generateHeaders(accessToken)

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        study_id: studyId,
        study_file_urls: studyFileUrls,
        insitution_name: institutionName,
        name,
        selected_product: selectedProduct,
        reason_for_evaluation: reasonForEvaluation
      })
    }

    const response = await fetch(url, options)

    return await this._processResponse(response)
  }
}
