/* eslint-disable no-return-assign */
// This component renders the status message displayed at the bottom
// of the table when results are loading or errored

const RenderStatus = ({ state }) => {
  let message
  if (state.isIndexLoading) {
    message = 'Indexing Patients'
  } else if (state.isIndexError) {
    message = 'Error Indexing Patients'
  } else if (state.results.length === 0) {
    message = 'No Results Available'
  } else {
    return message = null
  }

  return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '15px' }}>
        {
          (state.isDownloadLoading || state.isIndexLoading)
            ? <p>
              <a>{message}</a>{' '}
              <span className="dot">.</span>
              <span className="dot dot2">.</span>
              <span className="dot dot3">.</span>
            </p>
            : <p><a>{message}</a></p>
        }
      </div>
  )
}
export default RenderStatus
