// This component creates the analysis table (header and body)
// and calls the FetchMetric component for value reformating
import FetchMetric from './FetchMetric.jsx'

const RenderTable = ({ selectedItem, products, results, handlePatientModal, handleIsDownloadLoading, handleIsDownloadError, handleIsDownloadSuccess }) => {
  const renderMetricHeader = (selectedItem, products) => {
    const productObject = products.find(product => product.id === selectedItem)
    const metricList = productObject ? productObject.metrics : []

    return (
      <thead>
        <tr>
            {metricList.map((metric) => {
              return (
                <th key={metric}>
                    {metric}
                </th>
              )
            })}
        </tr>
      </thead>
    )
  }

  const renderTableRows = (selectedItem, products, results, handlePatientModal) => {
    const productObject = products.find(product => product.id === selectedItem)
    const metricList = productObject ? productObject.metrics : []
    return results.map((analyses, i) => {
      return (
        <tr className="py-4" key={i} data-analysis-id={analyses.id}>
        {
          metricList.map((metric, j) => {
            return (
              <td key={`${i}-${j}`}>
                  <FetchMetric
                      analyses={analyses}
                      metric={metric}
                      handlePatientModal={handlePatientModal}
                      handleIsDownloadLoading={handleIsDownloadLoading}
                      handleIsDownloadError={handleIsDownloadError}
                      handleIsDownloadSuccess={handleIsDownloadSuccess}
                  />
              </td>
            )
          })
        }
        </tr>
      )
    })
  }

  return (
    <table className="table" style={{ fontSize: '16px' }}>
        {renderMetricHeader(selectedItem, products)}
          <tbody>
              {renderTableRows(selectedItem, products, results, handlePatientModal)}
          </tbody>
    </table>
  )
}

export default RenderTable
