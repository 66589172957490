import ApiRequest from './ApiRequest'
import React from 'react'

class EvaluationSubmitStudy extends React.Component {
  async callCreateDraft () {
    const response = await new ApiRequest().createDraft()

    if (response.study_id === undefined) {
      return false
    }

    return response.study_id
  }

  async uploadStudyFile (studyId, studyFile) {
    const formData = new FormData()
    formData.append('file', studyFile)
    const fileName = studyFile.name
    const fileUrlResponse = await new ApiRequest().studyFileUpload(studyId, fileName)
    const fileUrl = fileUrlResponse.studyfile_upload_url
    const submitPath = fileUrl.split('?')[0]
    const fileuploadResponse = await fetch(fileUrl, {
      method: 'PUT',
      headers: {
        'x-amz-server-side-encryption': 'AES256',
        'Content-Type': 'application/octet-stream'
      },
      body: studyFile
    })
    if (fileuploadResponse.ok) {
      console.log(fileName, 'Uploaded succesfully')

      return [submitPath]
    } else {
      console.error(fileName, 'Upload failed')

      return false
    }
  }

  async submitDraft (studyId, studyFileUrls, analysisType, institutionName, name, selectedProduct, reasonForEvaluation) {
    const submitStatus = await new ApiRequest().submitStudy(studyId, studyFileUrls, analysisType, institutionName, name, selectedProduct, reasonForEvaluation)

    if (submitStatus.status === undefined || submitStatus.status !== 'Submitted') {
      return false
    }

    return await submitStatus
  }

  async submitStudy (studyFile, institutionName, name, selectedProduct, reasonForEvaluation) {
    try {
      const studyId = await this.callCreateDraft()

      if (studyId === false) {
        return false
      }

      const studyFileUrls = await this.uploadStudyFile(studyId, studyFile)

      if (studyFileUrls === false) {
        return false
      }

      const submitStatus = await this.submitDraft(studyId, studyFileUrls, institutionName, name, selectedProduct, reasonForEvaluation)

      if (submitStatus === false) {
        return false
      }

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }
}

export default EvaluationSubmitStudy
