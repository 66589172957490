import 'chartjs-adapter-moment'
import DemoData from './DemoData.json'
import { Modal, Button } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

Chart.register(...registerables)

const PatientCharts = ({ handlePatientModalProp, PatientReference }) => {
  const dataCopy = [...DemoData]
  const orderedData = dataCopy.sort((a, b) => new Date(b.DateofScan).getTime() - new Date(a.DateofScan).getTime())

  const filteredData = orderedData.filter(item => item.PatientReference === PatientReference)

  const minDateofScan = filteredData.reduce(
    (min, cur) => cur.DateofScan < min ? cur.DateofScan : min,
    filteredData[0].DateofScan
  )
  console.log('MIN DATE: ', minDateofScan)

  const maxDateofScan = filteredData.reduce(
    (max, cur) => cur.DateofScan > max ? cur.DateofScan : max,
    filteredData[0].DateofScan
  )
  console.log('MAX DATE: ', maxDateofScan)

  const dateOfScanList = filteredData.map(item => item.DateofScan)

  const Ct1Data = filteredData.map(item => ({ x: item.DateofScan, y: item.cT1 }))

  const PDFFData = filteredData.map(item => ({ x: item.DateofScan, y: item.PDFF }))

  const comboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: '14'
          },
          boxWidth: 2
        },
        onClick: () => {} // Disable the click interaction on the legend
      }
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy', // Format for displaying months
            year: 'YYYY' // Format for displaying years (bold)
          },
          tooltipFormat: 'yyyy-mm-dd' // Format for tooltip
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'cT1 (ms)'
        },
        type: 'linear',
        display: true,
        position: 'left',
        suggestedMin: 750,
        suggestedMax: 900
      },
      y1: {
        title: {
          display: true,
          text: 'Liver Fat Fraction (%)'
        },
        type: 'linear',
        display: true,
        position: 'right',
        suggestedMin: 4,
        suggestedMax: 6,
        grid: {
          drawOnChartArea: false
        }
      }
    },
    interaction: {
      mode: 'nearest'
    }
  }

  const chartDataCombined = {
    datasets: [{
      label: 'cT1 Values',
      data: Ct1Data,
      fill: false,
      borderColor: 'rgb(0, 67, 126)',
      tension: 0.1,
      yAxisID: 'y'
    },
    {
      label: 'PDFF Values',
      data: PDFFData,
      fill: false,
      borderColor: 'rgb(200, 157, 33)',
      tension: 0.1,
      yAxisID: 'y1'
    },
    {
      label: 'cT1 Upper Limit of Normal',
      data: [{ x: minDateofScan, y: 800 }, { x: maxDateofScan, y: 800 }],
      fill: false,
      borderColor: 'rgb(0, 67, 126, 0.5)',
      tension: 0.1,
      yAxisID: 'y',
      borderDash: [10, 5]
    },
    {
      label: 'PDFF Upper Limit of Normal',
      data: [{ x: minDateofScan, y: 5 }, { x: maxDateofScan, y: 5 }],
      fill: false,
      borderColor: 'rgb(200, 157, 33, 0.5)',
      tension: 0.1,
      yAxisID: 'y1',
      borderDash: [10, 5]
    }
    ]
  }

  return (
    <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}>
    <Modal size="lg" show={handlePatientModalProp} onHide={handlePatientModalProp} style={{ paddingRight: '0px' }}>
      <Modal.Header style ={{ padding: '15px 10px' }}>
        <Modal.Title style={{ fontSize: '26px' }}>LiverMultiscan results for: {PatientReference} </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', minHeight: '47vh', maxHeight: '83vh', padding: '5px' }}>
        {/* Images container with horizontal scroll */}
        <div style={{
          display: 'flex',
          overflowX: 'auto', // Enables horizontal scrolling for this container
          padding: '10px 0px',
          justifyContent: 'space-around'
        }}>
          {dateOfScanList.reverse().map((item, index) => (
            <div style={{ // eslint-disable-line react/jsx-key
              flex: 'none', // Prevents the items from shrinking
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: ' 10px 35px',
              maxHeight: '30vh'
            }}>
              <a>{item}</a>
              <img
                key={index}
                src={`/demo-pages/${PatientReference}/${item}.png`}
                style={{
                  height: '20vh',
                  borderRadius: '10px'
                }}
              />
            </div>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          {/* <div style={{ display: "flex", justifyContent: "left", padding: "10px" }}>
          <button className="evaluation-button-chart" style={{
            fontWeight: "bold",
            textDecoration: "underline"
          }}>
            cT1 & PDFF
          <CustomInfoButton link = "https://www.perspectum.com/our-products"/>
          </button>
          </div> */}
          <div className="label3" style={{ display: 'flex' }}>
            <Line data={chartDataCombined} options={comboChartOptions} style={{ padding: ' 0px 10px' }}/>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ padding: '10px' }} >
        <Button style={{ maxHeight: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="outline-secondary" size="sm" disabled>
          Send to Patient
          <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '10px' }} />
        </Button>
        <Button style={{ maxHeight: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="outline-secondary" size="sm" onClick={handlePatientModalProp}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}

PatientCharts.propTypes = {
  handlePatientModalProp: PropTypes.any.isRequired,
  PatientReference: PropTypes.string.isRequired
}

export default PatientCharts
