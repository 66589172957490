import { useState } from 'react'
import CustomInfoButton from '../../templates/infoButton.jsx'
import CustomRefreshButton from '../../templates/refreshButton.jsx'
import PatientCharts from './PatientDemoCharts.jsx'
import LMSResults from './LMSDemoResults.jsx'

const EvaluationResults = () => {
  const [selectedItem, setSelectedItem] = useState('item1')
  const [modalPatientReference, setModalPatientReference] = useState('')
  const [isModalVisible, setIsPatientModalVisible] = useState(false)

  const handleButtonClick = (value) => {
    setSelectedItem(value)
  }

  const handlePatientModal = (PatientReference) => {
    setModalPatientReference(PatientReference)
    setIsPatientModalVisible(!isModalVisible)
    console.log('handlePatientModal')
  }

  const loadAnalyses = () => {}

  return (
    <div style={{ display: 'flow', justifyContent: 'center', marginLeft: '150px', marginRight: '150px' }}>
      <div>
      <h4>Evaluation DEMO Results</h4>
      </div>
      <div className="label2" >
      <div className="product-option-container">
        <button onClick={() => handleButtonClick('item1')} className="evaluation-button" style={{
          fontWeight: selectedItem === 'item1' ? 'bold' : 'normal',
          textDecoration: selectedItem === 'item1' ? 'underline' : 'none'
        }}>
          LiverMultiScan
        <CustomInfoButton link = "https://www.perspectum.com/our-products/livermultiscan"/>
        </button>
        <button onClick={() => handleButtonClick('item2')} className="evaluation-button" style={{
          fontWeight: selectedItem === 'item2' ? 'bold' : 'normal',
          textDecoration: selectedItem === 'item2' ? 'underline' : 'none'
        }}>
          MRCP+
          <CustomInfoButton link = "https://www.perspectum.com/our-products/mrcpplus"/>
        </button>
        <button onClick={() => handleButtonClick('item3')} className="evaluation-button" style={{
          fontWeight: selectedItem === 'item3' ? 'bold' : 'normal',
          textDecoration: selectedItem === 'item3' ? 'underline' : 'none'
        }}>
          CoverScan
          <CustomInfoButton link = "https://www.perspectum.com/our-products/coverscan"/>
        </button>
        <button onClick={() => handleButtonClick('item4')} className="evaluation-button" style={{
          fontWeight: selectedItem === 'item4' ? 'bold' : 'normal',
          textDecoration: selectedItem === 'item4' ? 'underline' : 'none'
        }}>
          Hepatica
          <CustomInfoButton link = "https://www.perspectum.com/our-products/hepatica"/>
        </button>
        <div>
          <CustomRefreshButton handleRefresh={loadAnalyses} className={'refresh-button-custom-position'} />
        </div>
      </div>
      {selectedItem === 'item1' && (
        <LMSResults handlePatientModalProp={handlePatientModal}/>
      )}
    </div>

    {isModalVisible
      ? (
         <PatientCharts handlePatientModalProp={handlePatientModal} PatientReference={modalPatientReference}/>
        )
      : null}

    </div>
  )
}

export default EvaluationResults
