// This component renders the product selection menu from the products specified in state
// main component
// it also returns the currently selected product
import React from 'react'
import CustomInfoButton from '../templates/infoButton.jsx'
import CustomRefreshButton from '../templates/refreshButton.jsx'

const RenderProductOptions = ({ handleProductClick, products, selectedItem, loadAnalyses }) => {
  return (
    <div className="product-option-container">
      {products.map(item => (
        <button
          key={item.id}
          onClick={() => handleProductClick(item.id)}
          className={'evaluation-button'}
          style={{
            fontWeight: selectedItem === item.id ? 'bold' : 'normal',
            textDecoration: selectedItem === item.id ? 'underline' : 'none'
          }}
        >
          {item.text}
          <CustomInfoButton link={item.link} />
        </button>
      ))}
      <div>
        <CustomRefreshButton handleRefresh={loadAnalyses} className={'refresh-button-custom-position'} />
      </div>
    </div>
  )
}

export default RenderProductOptions
