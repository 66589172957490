import React, { useState } from 'react'
import EvaluationSubmitStudy from '../../utils/EvaluationSubmitStudy'
import { Modal, Button } from 'react-bootstrap'

import FileInput from '../templates/FileInput.jsx'

import JSZip from 'jszip'

function SubmitStudy () {
  // Field Variables
  const [studyFile, setStudyFile] = useState(null)
  const [selectedProduct, setAnalysisType] = useState('')
  const [institutionName, setInstitutionName] = useState('')
  const [uploaderName, setUploaderName] = useState('')
  const [reasonForEvaluation, setReasonForEvaluation] = useState('')

  // Terms & Conditions Variables
  const [isZipping, setIsZipping] = useState(false)
  const [isTCModalVisible, setIsTCModalVisible] = useState(false)
  const [isTCCheckboxChecked, setIsTCCheckboxChecked] = useState(false)
  const [isTCButtonClicked, setIsTCButtonClicked] = useState(false)

  // Loading State Variables
  const [isAPILoading, setIsAPILoading] = useState(false)
  const [isAPISuccess, setIsAPISuccess] = useState(false)
  const [isAPIError, setIsAPIError] = useState(false)
  const [isSubmitSuccessModalVisible, setIsSubmitSuccessModalVisible] = useState(false)
  const [showSubmitWarning, setShowSubmitWarning] = useState(false) // eslint-disable-line no-unused-vars

  const isFormValid = () => {
    return studyFile && selectedProduct && institutionName && uploaderName && isTCCheckboxChecked && isTCButtonClicked
  }

  const fileURL = '/Evaluation Agreement US v4.0 - Clean Version.pdf'

  // Field Change Handlers

  const handleAnalysisTypeChange = (event) => {
    setAnalysisType(event.target.value)
  }

  const handleInstitutionNameChange = (event) => {
    setInstitutionName(event.target.value)
  }

  const handleUploaderNameChange = (event) => {
    setUploaderName(event.target.value)
    setShowSubmitWarning(false) // Resetting the warning here
  }

  const handleCommentChange = (event) => {
    setReasonForEvaluation(event.target.value)
  }

  const resetForm = () => {
    setAnalysisType('')
    setInstitutionName('')
    setUploaderName('')
    setReasonForEvaluation('')
    setStudyFile(null)
    setIsTCCheckboxChecked(false)
    setIsTCButtonClicked(false)
  }

  // Terms & Conditions Modal Handlers

  const handleOpenTCModal = () => {
    setIsTCModalVisible(true)
  }

  const handleCloseTCModal = () => {
    setIsTCModalVisible(false)
  }

  const handleTCCheckbox = (event) => {
    setIsTCCheckboxChecked(event.target.checked)
  }

  const handleTCButton = () => {
    setIsTCButtonClicked(true)
    handleCloseTCModal()
  }

  // Form Submission Handlers
  const submitPressed = async () => {
    if (!isFormValid()) {
      alert('Please fill in all mandatory fields and agree to the Terms and Conditions.')
      return
    }
    setIsAPILoading(true)
    setIsAPISuccess(false)
    setIsAPIError(false)

    const isSubmitted = await new EvaluationSubmitStudy().submitStudy(studyFile, institutionName, name, selectedProduct, reasonForEvaluation)

    setTimeout(() => {
      setIsAPILoading(false)
      if (isSubmitted) {
        setIsAPISuccess(true)
        setIsSubmitSuccessModalVisible(true) // Show the success modal.
        resetForm()
      } else {
        setIsAPIError(true)
      }
    }, 750)
  }

  const onDrop = React.useCallback(async (acceptedFiles) => {
    // Check if only one file is uploaded and if it's a zip file
    if (acceptedFiles.length === 1 && acceptedFiles[0].name.endsWith('.zip')) {
      setStudyFile(acceptedFiles[0])
      return
    }

    setIsZipping(true)
    const zip = new JSZip()

    try {
      acceptedFiles.forEach((file) => {
        const relativePath = file.path.startsWith('/') ? file.path.substring(1) : file.path
        zip.file(relativePath, file)
      })

      const zipFile = await zip.generateAsync({ type: 'blob' })
      let fileName

      if (acceptedFiles.length === 1) {
        // If there's only one file, use its name for the zip file (without extension)
        fileName = acceptedFiles[0].name.replace(/\.[^/.]+$/, '')
      } else {
        // If multiple files, get the first part of the path for the zip file's name
        const firstPartOfPath = acceptedFiles[0].path.split('/')[1]
        console.log('firstPartOfPath: ', firstPartOfPath)
        // Check if firstPartOfPath is 'unknown', in which case use "n_files" as the name
        if (firstPartOfPath === 'unknown' || !firstPartOfPath) {
          fileName = `${acceptedFiles.length} files`
        } else {
          fileName = `${firstPartOfPath}`
        }
      }

      const namedZipFile = new File([zipFile], `${fileName}.zip`, { type: 'application/zip' })

      setStudyFile(namedZipFile)
      setIsZipping(false)
    } catch (error) {
      console.error('Error zipping files: ', error)
      setIsZipping(false)
    }
  }, [setStudyFile, setIsZipping])

  const renderSuccessModal = () => {
    return (
      <Modal size="lg"
        show={isSubmitSuccessModalVisible}
        onHide={() => setIsSubmitSuccessModalVisible(false)}
        centered // Centers the modal vertically in the window
      >
        <Modal.Body>
          <div className="text-center">
            <h5>Thank you!</h5>
            <p>Your study has succesfully been submitted for evaluation.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="success-button" onClick={() => setIsSubmitSuccessModalVisible(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    )
  }

  const renderSubmit = () => {
    if (!isTCCheckboxChecked || !isTCButtonClicked) {
      return
    };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
        {/* Uploading Message */}
        {isAPILoading && (
          <div className="alert alert-info my-4" style={{ width: '100%', textTransform: 'none' }}>
            <p>Loading, please wait<span className="dot">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span></p>
          </div>
        )}

        {/* Incomplete form message */}
        {!isAPILoading && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {!isFormValid() && (
              <p style={{ color: 'red' }}>
                Please fill in all mandatory fields
              </p>
            )}

        {/* Submit Button */}
            <button
              onMouseDown={() => !isFormValid() && setShowSubmitWarning(true)}
              onClick={submitPressed}
              className="submit-button-arrow"
              style={{ marginBottom: '30px', marginLeft: 'auto' }} // marginLeft: auto will push the button to the right
              disabled={!isFormValid()}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    )
  }

  const renderSubmitFeedback = () => {
    if (isAPIError) {
      return (
        <div className="alert alert-danger my-4" style={{ textTransform: 'none', marginBottom: '30px' }}>
          Sorry, there was an error processing your request. Please check the form or try again later
        </div>
      )
    }

    if (isAPISuccess) {
      return (
        renderSuccessModal()
      )
    }
  }

  const renderTCModal = () => {
    if (!isTCModalVisible) {
      return
    }

    return (
      <div>
        <Modal size="xl" show={isTCModalVisible} onHide={handleCloseTCModal}>
          <Modal.Header>
            <Modal.Title>Terms And Conditions </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}>
            <div style={{ justifyContent: 'center', display: 'flex', flex: 1, overflow: 'auto', backgroundColor: '#dcdcdc21', width: '100%', height: '100%' }} id="termsRef">
              <iframe src={fileURL} title="TnS Pdf" style={{ display: 'flex', width: '100%' }} ></iframe>
            </div>
            <div style={{ marginTop: '10px' }}>
              <label>
                <input type="checkbox" checked={isTCCheckboxChecked} onChange={handleTCCheckbox} style={{ width: '15px', height: '15px', marginRight: '10px', padding: '40px 10px' }} />
                I (Uploader) am authorised to accept the Terms and Conditions on behalf of the provided institution.
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer >
            <Button onClick={handleTCButton} disabled={!isTCCheckboxChecked} style={{ backgroundColor: isTCCheckboxChecked ? '#007BFF' : '#888888', borderColor: isTCCheckboxChecked ? '#007BFF' : '#888888', maxHeight: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="outline-primary" size="sm">
              Accept
            </Button>
            <Button style={{ maxHeight: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="outline-secondary" size="sm" onClick={handleCloseTCModal}>
              Decline
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  return (
    <div className="container align-center" style={{ marginLeft: '150px', width: '600px', minHeight: '700px' }} >
      <h4>Upload Evaluation</h4>
      <div className="label">
        <form>
          <div className="form-group" style={{ display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '10px' }}>
            <label htmlFor="Product" style={{ marginTop: '10px' }}>Product *</label>
            <select value={selectedProduct} onChange={handleAnalysisTypeChange} type="text" id="product" name="productDropdown" disabled={isAPILoading} style={{ height: '42px' }} className="form-control" placeholder="Select a product">
              <option value="" disabled>Select a product</option>
              <option value="LMS">LiverMultiScan Evaluation Study</option>
              <option value="MRCPPLUS">MRCP+ Evaluation Study</option>
              <option value="CoverScan">CoverScan Evaluation Study</option>
              <option value="Hepatica">Hepatica Evaluation Study</option>
            </select>
          </div>
          <div className="form-group" style={{ display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '10px' }}>
            <label htmlFor="instutionName">Institution Name: *</label>
            <input type="name" id="instutionName" name="instutionName" placeholder="Enter institution name" value={institutionName} className="form-control" onChange={handleInstitutionNameChange} disabled={isAPILoading} />
          </div>
          <div className="form-group" style={{ display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '10px' }}>
            <label htmlFor="uploaderName">Uploader Name *</label>
            <input type="name" id="uploaderName" name="uploaderName" placeholder="Enter your name" className="form-control" value={uploaderName} onChange={handleUploaderNameChange} disabled={isAPILoading} />
          </div>
          <div className="form-group" style={{ display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '20px' }}>
            <label htmlFor="comment">Reason for Evaluation </label>
            <textarea id="comment" name="comment" className="form-control" rows="3" value={reasonForEvaluation} onChange={handleCommentChange} disabled={isAPILoading} />
          </div>
        </form>
        <div style={{ display: 'flex', justifyContent: 'left', padding: '20px 0px' }}>
          <button onClick={handleOpenTCModal} className="tns-button">
            Terms and Conditions
          </button>
        </div>
        <FileInput
          onDrop={onDrop}
          isAPILoading={isAPILoading}
          isTCCheckboxChecked={isTCCheckboxChecked}
          isTCButtonClicked={isTCButtonClicked}
          studyFile={studyFile}
          isZipping={isZipping}
        />
        {renderSubmit()}
        {renderSubmitFeedback()}
        {renderTCModal()}
      </div>
    </div>
  )
};

export default SubmitStudy
