import React from 'react'

const LoadingSpinner = ({
  isDownloadLoading,
  isDownloadError,
  isDownloadSuccess,
  handleShowDownloadSpinner
}) => {
  let content

  const closeButton = (
        <button onClick={handleShowDownloadSpinner} className="spinner-close-button">Close</button>
  )

  if (isDownloadLoading) {
    content = (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
          <p className='spinner-text'>Fetching Your report</p>
          {closeButton}
        </div>
    )
  } else if (isDownloadError) {
    content = (
        <div className="loading-spinner-container">
          <span className="error-icon">&#10060;</span> {/* Red Cross */}
          <p className='spinner-text'>Error fetching report</p>
          {closeButton}
        </div>
    )
  } else if (isDownloadSuccess) {
    content = (
        <div className="loading-spinner-container">
          <span className="success-icon">&#10004;</span> {/* Green Tick */}
          <p className='spinner-text'>Report downloaded successfully</p>
          {closeButton}
        </div>
    )
  }

  return content
}

export default LoadingSpinner
