import { loginRequest, msalInstance } from '../../authConfig'

export default function SignIn () {
  const DownloadUserGuide = () => {
    console.log('Downloading User Guide')
    const link = document.createElement('a')
    link.href = '/Perspectum Platform Start Up Guide v1.0 2.pdf'
    link.setAttribute('download', 'CARP User Guide.pdf')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      <div className="banner parallax align-left ">
        <picture>
          <source media="(min-width: 768px)" srcSet="hero-background.png?cc=0.022408205898923794,0.3559422547131903,0.432754281910558,0.3035343001677359&amp;width=2560&amp;height=1600&amp;rnd=132998119306370000 2560w,
          hero-background.png?cc=0.020447300259015762,0.3011586000372497,0.2894251842996614,0.2675117028119234&amp;width=1920&amp;height=1200&amp;rnd=132998119306370000 1920w,
          hero-background.png?cc=0.015334376428395706,0.3247729097778824,0.37572958053514305,0.29464206332432924&amp;width=1440&amp;height=900&amp;rnd=132998119306370000 1440w" />
          <source media="(min-width: 320px)" srcSet="{{ url_for('static', filename='hero-background.png') }}?cc=0.08214032213303733,0.2796042449406541,0.45572071663393393,0.2582567938263172&amp;width=2304&amp;height=2304&amp;rnd=132998119306370000 2304w,
          hero-background.png?cc=0.06079287101870038,0.29027797049782256,0.4770681677482709,0.24758306826914875&amp;width=1152&amp;height=1152&amp;rnd=132998119306370000 1152w,
          hero-background.png?cc=0.01872338239689182,0.2366452791890815,0.4421144961645746,0.22419259937238495&amp;width=576&amp;height=576&amp;rnd=132998119306370000 576w" />
          <img className="banner-background-img" src="hero-background.png" alt="" style={{ tranform: 'translateY(0px)' }} />
        </picture>

        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-content">
              <div className="row">
                <div className="col-8 col-sm-6">
                  <h1 className="cover-heading">Physicians and healthcare teams</h1>
                  <p className="lead">Click the button below to access your patients’ enhanced imaging reports.</p>
                  <p className="lead pt-3">
                    <a href="#" onClick={() => msalInstance.loginRedirect(loginRequest)} className="standard-btn btn-lg colour-blue">Access</a>
                  </p>
                  <p className="mt-4 text-muted" style={{ fontSize: '0.8rem' }}>
                  New? Grab our <a href="#" onClick={DownloadUserGuide} style={{ textDecoration: 'underline' }}> USER GUIDE</a>
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
