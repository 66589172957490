/* eslint-disable react/no-unescaped-entities */
// FileInput.js

import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const getColor = (props) => {
  if (props.$isDragAccept) {
    return '#00e676'
  }
  if (props.$isDragReject) {
    return '#ff1744'
  }
  if (props.$isFocused) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  &:focus-within {
    border-color: #2196f3; /* Consider moving styling into getColor function if necessary */
  }
`

const FileNameDisplay = styled.p`
  margin-top: 8px;
`

const Container = React.forwardRef(({ $isFocused, $isDragAccept, $isDragReject, ...rest }, ref) => (
    <StyledContainer ref={ref} $isFocused={$isFocused} $isDragAccept={$isDragAccept} $isDragReject={$isDragReject} {...rest} />
))
Container.displayName = 'Container'

const FileInput = ({ onDrop, isAPILoading, isTCCheckboxChecked, isTCButtonClicked, studyFile, isZipping }) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: true,
    disabled: isAPILoading
  })

  if (!isTCCheckboxChecked || !isTCButtonClicked) {
    return
  }

  // Update the props passed to Container here as well to use transient props
  return (
    <div>
      <Container {...getRootProps({ $isFocused: isFocused, $isDragAccept: isDragAccept, $isDragReject: isDragReject })}>
        <input {...getInputProps()} />

        {studyFile
          ? (
          <FileNameDisplay>{studyFile.name.replace(/\.zip$/, '')}</FileNameDisplay>
            )
          : isZipping
            ? (
          <p>Zipping Please Wait ...</p>
              )
            : (
          <p>Drag and drop some files here, or click to select files</p>
              )}

      </Container>
    </div>
  )
}

export default FileInput
