import PropTypes from 'prop-types'
import DemoData from './DemoData.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const LMSResults = ({ handlePatientModalProp }) => {
  const dataCopy = [...DemoData]
  const orderedData = dataCopy.sort((a, b) => new Date(b.DateofScan).getTime() - new Date(a.DateofScan).getTime())

  const DemoDownloadButton = (fileName) => {
    console.log('Downloading: ', fileName)
    const link = document.createElement('a')
    link.href = `/demo-reports/${fileName}.pdf`
    link.setAttribute('download', `${fileName}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const renderTableRows = () => {
    return orderedData.map((item, index) => (
      <tr key={index}>
        <td>
          <a href="#" onClick={() => { handlePatientModalProp(item.PatientReference) }}>
          {item.PatientReference}
          </a>
        </td>
        <td>{item.DateofScan}</td>
        <td>{item.ReferringPhysician}</td>
        <td>{item.cT1}ms</td>
        <td>{item.LIC}mg Fe/g</td>
        <td>{item.PDFF}%</td>
        <td>
          <button className="report-download-button" onClick={() => DemoDownloadButton(item.FileName)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
          </button>
        </td>
        <td style={{ justifyContent: 'center', alignItems: 'center' }}>
        <button className="report-download-button" disabled>
          <FontAwesomeIcon icon={faEnvelope} />
        </button>
        </td>
      </tr>
    ))
  }

  return (
    <table className="table" style={{ fontSize: '16px' }}>
      <thead>
        <tr>
          <th>Patient Reference</th>
          <th>Date of Scan</th>
          <th>Referring Physician</th>
          <th>cT1</th>
          <th>LIC</th>
          <th>PDFF</th>
          <th>Results</th>
          <th style={{ maxWidth: '15px' }}>Send to Patient</th>
        </tr>
      </thead>
      <tbody>
        {renderTableRows()}
      </tbody>
    </table>
  )
}

LMSResults.propTypes = {
  handlePatientModalProp: PropTypes.any.isRequired
}

export default LMSResults
