import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'
import { msalInstance } from './authConfig'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import GuestNavbar from './components/layout/GuestNavbar'
import AuthNavbar from './components/layout/AuthNavbar'
import SignIn from './components/pages/SignIn'
import SubmitStudy from './components/pages/SubmitStudy'
import EvaluationResults from './components/pages/EvaluationResultsPages/EvaluationResults'
import ClinicalStudies from './components/pages/ClinicalPages/ClinicalStudies'
import EvaluationDemoResults from './components/pages/EvaluationDemoPages/EvaluationDemoResults'

export default function App () {
  return (
    <Router>
      <MsalProvider instance={msalInstance}>
        <Routes>
          <Route
            path="/submit-evaluation"
            element={
              <AuthenticatedTemplate>
                <AuthNavbar />
                <SubmitStudy />
              </AuthenticatedTemplate>
            }
          />
          <Route
            path="/evaluation-results"
            element={
              <AuthenticatedTemplate>
                <AuthNavbar />
                <EvaluationResults />
              </AuthenticatedTemplate>
            }
          />
          <Route
            path="/evaluation-demo-results"
            element={
              <AuthenticatedTemplate>
                <AuthNavbar />
                <EvaluationDemoResults />
              </AuthenticatedTemplate>
            }
          />
          <Route
            path="/"
            element={
              <>
                <UnauthenticatedTemplate>
                  <GuestNavbar />

                  <main>
                    <SignIn />
                  </main>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                  <AuthNavbar />

                  <main>
                    <ClinicalStudies />
                  </main>
                </AuthenticatedTemplate>
              </>
            }
          />
        </Routes>
      </MsalProvider>
    </Router>
  )
}
