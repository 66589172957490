// This component renders the Evaluation Results page.
// By calling the  evaluationIndex endpoint and passing that onto render components
// If you wish to add a product here consult the pages readme
// todo: futher module out the page rendering part
// todo: Maybe its worth rebuilding the way we have a state and individual handlers that change the state
//       currently its adding a lot of boiler plate
import React from 'react'
import ApiRequest from '../../../utils/ApiRequest'
import RenderProductOptions from '../../Render/RenderProductOptions.jsx'
import RenderTable from '../../Render/RenderTable.jsx'
import RenderStatus from '../../Render/RenderStatus.jsx'
import RenderPagination from '../../Render/RenderPagination.jsx'
import PatientCharts from '../../Render/PatientCharts.jsx'
import LoadingSpinner from '../../templates/LoadingSpinner.jsx'

class EvaluationResults extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [
        {
          id: 'lms',
          text: 'LiverMultiScan',
          link: 'https://www.perspectum.com/our-products/livermultiscan',
          metrics: ['Patient', 'Date of Scan', 'Referring Physician', 'cT1', 'LIC', 'PDFF', 'Results']
        },
        {
          id: 'mrcpp',
          text: 'MRCP+',
          link: 'https://www.perspectum.com/our-products/mrcpplus',
          metrics: ['Patient', 'Date of Scan', 'Referring Physician', 'Results']
        },
        {
          id: 'coverscan',
          text: 'CoverScan',
          link: 'https://www.perspectum.com/our-products/coverscan',
          metrics: ['Patient', 'Date of Scan', 'Referring Physician', 'Results']
        },
        {
          id: 'hepatica',
          text: 'Hepatica',
          link: 'https://www.perspectum.com/our-products/hepatica',
          metrics: ['Patient', 'Date of Scan', 'Referring Physician', 'Results']
        }

      ],
      selectedItem: 'lms',
      isModalVisible: false,
      showDownloadSpinner: false,
      results: [],
      isIndexLoading: false,
      isIndexError: false,
      isDownloadLoading: false,
      isDownloadError: false,
      isDownloadSuccess: false,
      hasAttemptedReload: false,
      page: 1,
      perPage: 100,
      pagination: {},
      selected_analysisId: '',
      selected_patientName: ''
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.isIndexError && !prevState.isIndexError && !this.state.hasAttemptedReload) {
      console.log('Error loading analyses')
      this.setState({ hasAttemptedReload: true }, () => {
        this.loadAnalyses()
      })
    }
  }

  componentDidMount () {
    this.loadAnalyses()
  }

  loadAnalyses = async () => {
    this.setState({ ...this.state, isIndexLoading: true })

    try {
      const analyses = await new ApiRequest().evaluationIndex(this.state.page, this.state.perPage, this.state.selectedItem)
      this.setState({
        ...this.state,
        isIndexLoading: false,
        pagination: analyses.pagination,
        results: analyses.results
      })
    } catch (e) {
      this.setState({
        ...this.state,
        isIndexLoading: false,
        isIndexError: true
      })

      console.log(e)
    }

    console.log('Loaded Results')
  }

  handleProductClick = async (value) => {
    await this.setState({
      ...this.state,
      selectedItem: value,
      results: []
    })
    this.loadAnalyses()
  }

  handlePatientModal = (analysisId, patientName) => {
    this.setState({
      selected_analysisId: analysisId,
      selected_patientName: patientName,
      isModalVisible: !this.state.isModalVisible
    })
  }

  handleIsDownloadLoading = async (value) => {
    this.setState({
      isDownloadLoading: value,
      showDownloadSpinner: true
    })
  }

  handleIsDownloadError = async (value) => {
    this.setState({
      isDownloadError: value
    })
  }

  handleIsDownloadSuccess = async () => {
    this.setState({
      isDownloadSuccess: true,
      isDownloadLoading: false
    })
  }

  handleShowDownloadSpinner = async () => {
    this.setState({
      showDownloadSpinner: false,
      isDownloadSuccess: false
    })
  }

  render () {
    return (
      <div style={{ display: 'flow', justifyContent: 'center', marginLeft: '150px', marginRight: '150px' }}>

        <div>
          <h4>Evaluation Results</h4>
        </div>

        <div className="label2">
          <RenderProductOptions
              handleProductClick={this.handleProductClick}
              products={this.state.products}
              loadAnalyses={this.loadAnalyses}
              selectedItem={this.state.selectedItem}/>

          <RenderTable
              selectedItem={this.state.selectedItem}
              products={this.state.products}
              results={this.state.results}
              handlePatientModal={this.handlePatientModal}
              handleIsDownloadLoading={this.handleIsDownloadLoading}
              handleIsDownloadError={this.handleIsDownloadError}
              handleIsDownloadSuccess={this.handleIsDownloadSuccess}
              />

          <RenderStatus
            state={this.state}
          />

          <RenderPagination
              state={this.state}
              setState={this.setState.bind(this)}
              loadAnalyses={this.loadAnalyses}/>

          {this.state.isModalVisible
            ? <PatientCharts
                  handlePatientModalProp={this.handlePatientModal}
                  PatientReference={this.state.selected_analysisId}
                  PatientName={this.state.selected_patientName}/>
            : null
          }
          {this.state.showDownloadSpinner && (
          <div className="modal-spinner-overlay">
            <LoadingSpinner
            isDownloadLoading={this.state.isDownloadLoading}
            isDownloadError={this.state.isDownloadError}
            isDownloadSuccess={this.state.isDownloadSuccess}
            handleShowDownloadSpinner={this.handleShowDownloadSpinner}
            />
          </div>
          )}
        </div>

      </div>
    )
  }
};

export default EvaluationResults
