export default function GuestNavbar () {
  return (
        <header>
            <div className="container">
                <div className="site-logo">
                    <a href="https://platform.perspectum.com/" title="Go to home page">
                        <img src="logo.png" alt="" className="img-fluid site-logo-colour" />
                    </a>
                </div>
                <div className="primary-navigation">
                    <div className="navigation-header">
                        <div className="navigation-logo">
                            <a href="https://platform.perspectum.com/" title="Go to home page">
                                <img src="logo.png" alt="" className="img-fluid site-logo-colour" />
                            </a>
                        </div>

                        <div className="mobile-close-btn">
                            <button>
                                <span>Close Menu</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mobile-nav-toggle">
                    <button>
                        Open menu
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </header>
  )
}
