import {
  LogLevel,
  PublicClientApplication,
  EventType
} from '@azure/msal-browser'
import env from './env'
import ApiRequest from './utils/ApiRequest'

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: env.REACT_APP_MSAL_SUSI_FLOW,
    forgotPassword: env.REACT_APP_MSAL_FORGOT_FLOW,
    editProfile: env.REACT_APP_MSAL_ACCOUNT_FLOW
  },
  authorities: {
    signUpSignIn: {
      authority: env.REACT_APP_MSAL_SUSI_AUTHORITY
    },
    forgotPassword: {
      authority: env.REACT_APP_MSAL_FORGOT_AUTHORITY
    },
    editProfile: {
      authority: env.REACT_APP_MSAL_ACCOUNT_AUTHORITY
    }
  },
  authorityDomain: env.REACT_APP_MSAL_AUTHORITY
}

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: env.REACT_APP_MSAL_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            break
          default:
        }
      }
    }
  }
}

export const scopes = [env.REACT_APP_MSAL_SCOPE]

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes
}

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    new ApiRequest().recordLogin()
  }
})
