// This component is called when handlePatientModal is called
// It receives patientName to display in the header and
// PatientReference (analysis id) which ti uses to query the analysis history endpoint via ApiRequest.js
// It uses this data to return a modal which displays ct1 and pdff data for a patient
// over time.
// A same patient is one determined to have matching dicom_tag
import 'chartjs-adapter-moment'
import ApiRequest from '../../utils/ApiRequest.js'
import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import PropTypes from 'prop-types'
import moment from 'moment'

Chart.register(...registerables)

const PatientCharts = ({ handlePatientModalProp, PatientReference, PatientName }) => {
  const [dataCopy, setDataCopy] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [Ct1Data, setCt1Data] = useState([]) // state variable for Ct1Data
  const [PDFFData, setPDFFData] = useState([])
  const [LoadingData, setLoadingData] = useState(true)
  const [minDateofScan, setMinDateofScan] = useState([])
  const [maxDateofScan, setMaxDateofScan] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const historyAnalyses = await new ApiRequest().analysisHistory(PatientReference)
        setDataCopy(historyAnalyses.data) // set the data into state variable
        setLoadingData(false)
      } catch (e) {
        console.log('ERROR HISTORY: ', e)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (!dataCopy.length) {
      setLoadingData(true)
    } else {
      const noDuplicateData = removeDuplicatesById(dataCopy)
      const filteredData = noDuplicateData.filter(item => Object.keys(item.results).length !== 0)

      setFilteredData(filteredData)

      const orderedData = filteredData.sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())

      setMinDateofScan(moment.min(orderedData.map(item => moment(item.started_at))))
      setMaxDateofScan(moment.max(orderedData.map(item => moment(item.started_at))))

      const mapDataToXY = (field) =>
        orderedData
          .filter(item => item.results[field])
          .map(item => ({ x: item.started_at, y: item.results[field] }))

      setCt1Data(mapDataToXY('liver_ct1_median_ms'))
      setPDFFData(mapDataToXY('liver_pdff_median_pct'))
    }
  }, [dataCopy])

  const removeDuplicatesById = (dataArray) => {
    const uniqueIds = new Set()
    return dataArray.filter((item) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id)
        return true
      }
      return false
    })
  }

  const comboChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: '14'
          },
          boxWidth: 2
        },
        onClick: () => {} // Disable the click interaction on the legend
      }
    },
    scales: {
      x: {
        display: true,
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            month: 'MMM yyyy', // Format for displaying months
            year: 'YYYY' // Format for displaying years (bold)
          },
          tooltipFormat: 'yyyy-mm-dd' // Format for tooltip
        },
        title: {
          display: true,
          text: 'Date'
        }
      },
      y: {
        title: {
          display: true,
          text: 'cT1 (ms)'
        },
        type: 'linear',
        display: true,
        position: 'left',
        suggestedMin: 750,
        suggestedMax: 900
      },
      y1: {
        title: {
          display: true,
          text: 'Liver Fat Fraction (%)'
        },
        type: 'linear',
        display: true,
        position: 'right',
        suggestedMin: 4,
        suggestedMax: 6,
        grid: {
          drawOnChartArea: false
        }
      }
    },
    interaction: {
      mode: 'nearest'
    }
  }

  const chartDataCombined = {
    datasets: [{
      label: 'cT1 Values',
      data: Ct1Data,
      fill: false,
      borderColor: 'rgb(0, 67, 126)',
      tension: 0.1,
      yAxisID: 'y'
    },
    {
      label: 'PDFF Values',
      data: PDFFData,
      fill: false,
      borderColor: 'rgb(200, 157, 33)',
      tension: 0.1,
      yAxisID: 'y1'
    },
    {
      label: 'cT1 Upper Limit of Normal',
      data: [{ x: minDateofScan, y: 800 }, { x: maxDateofScan, y: 800 }],
      fill: false,
      borderColor: 'rgb(0, 67, 126, 0.5)',
      tension: 0.1,
      yAxisID: 'y',
      borderDash: [10, 5]
    },
    {
      label: 'PDFF Upper Limit of Normal',
      data: [{ x: minDateofScan, y: 5 }, { x: maxDateofScan, y: 5 }],
      fill: false,
      borderColor: 'rgb(200, 157, 33, 0.5)',
      tension: 0.1,
      yAxisID: 'y1',
      borderDash: [10, 5]
    }
    ]
  }

  return (
    <div style={{ alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}>
    <Modal size="lg" show={handlePatientModalProp} onHide={handlePatientModalProp} style={{ paddingRight: '0px' }}>
      <Modal.Header style ={{ padding: '15px 10px' }}>
        <Modal.Title style={{ fontSize: '26px' }}>LiverMultiScan results for: {PatientName} </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', flexDirection: 'column', minHeight: '47vh', maxHeight: '83vh', padding: '5px' }}>
        <div style={{ flex: 1 }}>
          <div className="label3" style={{ display: 'flex' }}>
                {filteredData.length <= 1
                  ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <div style={{ padding: '150px 10px', textAlign: 'center' }}>
                    <p>Less than two scans with results available for this patient</p>
                    <p>Chart will be shown when this patient receives multiple scans</p>
                  </div>
                </div>
                  : <Line data={chartDataCombined} options={comboChartOptions} style={{ padding: ' 0px 10px' }}/>
                }
          </div>
          <div style={{ height: '60px', textAlign: 'center', lineheight: '60px', padding: '10px 0px' }}>
            {LoadingData
              ? <p>Loading Patient Data &nbsp;
                <span className="dot">.</span>
                <span className="dot dot2">.</span>
                <span className="dot dot3">.</span>
              </p>
              : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ padding: '10px' }} >
        <Button style={{ maxHeight: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} variant="outline-secondary" size="sm" onClick={handlePatientModalProp}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  )
}

PatientCharts.propTypes = {
  PatientReference: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
}

export default PatientCharts
