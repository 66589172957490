// This component is called by renderTable
// It receives an analysis object from results and a metric
// It will return the specific  formatted value from analyses for that metric
// If you want the table to display a new metric you will need to add it as a case
// statement bellow otherwise it will simply return as unknown
import moment from 'moment'
import ApiRequest from '../../utils/ApiRequest.js'

const FetchMetric = ({
  analyses,
  metric,
  handlePatientModal,
  handleIsDownloadLoading,
  handleIsDownloadError,
  handleIsDownloadSuccess
}) => {
  const downloadAnalysis = async (analysisId) => {
    handleIsDownloadLoading(true)
    try {
      const analysis = await new ApiRequest().analysisDownload(analysisId)

      const win = window.open(analysis.report.url, '_blank')
      win.focus()
      handleIsDownloadSuccess()
    } catch (e) {
      console.log('DOWNLOAD ERROR: ', e)
      handleIsDownloadLoading(false)
      handleIsDownloadError(true)
    }
  }

  const roundNumber = (num) => {
    if (num === undefined) return 'Unknown'
    const roundedNum = Math.round((num + Number.EPSILON) * 100) / 100
    return roundedNum.toString()
  }

  const metricOutput = (metricName, unit) => {
    const num = analyses.results[metricName]
    return num !== undefined ? `${roundNumber(num)}${unit}` : <i>Unknown</i>
  }

  // Analysis status returns
  const reportRejectedReturn = <div className="report-download-container"><i style={{ color: '#000000' }}>Not Analyzable</i></div>
  const underAnalysisReturn = <div className="report-download-container"><i style={{ color: '#a9a9a9' }}>Under Analysis</i></div>
  const reportAvailableReturn = (
    <div className="report-download-container">
      <button className="report-download-button" onClick={() => downloadAnalysis(analyses.id)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
        </svg>
      </button>
    </div>
  )

  switch (metric) {
    case 'Patient':
      if (analyses.patient.name === undefined) {
        return (
          <i>Unknown</i>
        )
      } else {
        return (
          <a>
          {analyses.patient.name}
          </a>
        )
      }

      // This functionality is temporarily disabled due to mix of clinical and evaluation results
      // on graphs
      // if (analyses.analysis_type_ref_key.includes('com.perspectum.clinical.lms')) {
      //   return (
      //     <a href="#" onClick={() => handlePatientModal(analyses.id, analyses.patient.name)}>
      //     {analyses.patient.name}
      //     </a>
      //   )
      // } else {
      //   return (
      //     <a>
      //     {analyses.patient.name}
      //     </a>
      //   )
      // }

    case 'Date of Scan':
      return moment(analyses.started_at).format('MMMM Do YYYY h:mm:ss a')

    case 'Referring Physician':
      // Check for Indiana first
      if (analyses.project === 'Clinical LMS (Indiana University Health)' && analyses.referrer.first_name === 'indiana') {
        return <i>Unknown</i>
      } else {
        // Display the referring physician's name or their dicom tag if there is no name
        return analyses.referrer.first_name
          ? (
          <>
              {analyses.referrer.first_name} {analyses.referrer.last_name}
          </>
            )
          : analyses.referrer.tag
            ? (
          <i>{analyses.referrer.tag.replaceAll('^', ' ')}</i>
              )
            : (
          <i>{analyses.referrer.id}</i>
              )
      }

    case 'Results':
      if (analyses.computed_status === 'Rejected') {
        return reportRejectedReturn
      } else if (analyses.computed_status === 'In Progress') {
        return underAnalysisReturn
      } else {
        return reportAvailableReturn
      }

    case 'PDFF':
      return metricOutput('liver_pdff_median_pct', ' %')

    case 'LIC':
      return metricOutput('liver_lic_median_mgpg', ' mg Fe/g')

    case 'cT1':
      return metricOutput('liver_ct1_median_ms', ' ms')

    default:
      return <i>Unknown</i>
  }
}

export default FetchMetric
