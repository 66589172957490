import { msalInstance } from '../../authConfig'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'

export default function AuthNavbar () {
  const [selectedItem, setSelectedItem] = useState('item3')
  const [navbarOpen, setNavbarOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setSelectedItem('item1')
        break
      case '/evaluation-results':
        setSelectedItem('item2')
        break
      case '/submit-evaluation':
        setSelectedItem('item3')
        break
      case '/evaluation-demo-results':
        setSelectedItem('item4')
        break
      default:
        setSelectedItem('item1')
        break
    }
  }, [location.pathname])

  const handleButtonClick = (value) => {
    setSelectedItem(value)
  }

  return (
    <header>
      <div style={{ padding: '0px 50px' }}>
        <nav className="navbar navbar-light navbar-expand-xl" style={{ color: 'black', display: 'flex' }}>
        <a href="/" title="Go to home page">
            {/* Logo for large screens */}
            <img
              src="logo.png"
              alt="Logo"
              className="d-none d-sm-block img-fluid site-logo-colour"
              style={{ maxWidth: '300px' }}
            />
            {/* Logo for small screens */}
            <img
              src="logo-sm.png"
              alt="Logo"
              className="d-block d-sm-none img-fluid site-logo-colour"
              style={{ maxWidth: '60px' }}
            />
          </a>

          <button
              className={`navbar-toggler ${navbarOpen ? '' : 'collapsed'}`}
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
              aria-controls="navbarNav"
              aria-expanded={navbarOpen}
              aria-label="Toggle navigation"
            >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse justify-content-end ${navbarOpen ? 'show bg-light' : ''}`} id="navbarNav">
            <ul className="nav navbar-nav">
              <li className="nav-item">
                <Link to="/">
                  <a
                    className="nav-link clinical-page-link"
                    onClick={() => handleButtonClick('item1')}
                    style={{
                      fontWeight: selectedItem === 'item1' ? 'bold' : '300',
                      textDecoration: selectedItem === 'item1' ? 'underline' : 'none',
                      textUnderlineOffset: '4px',
                      width: '160px'
                    }}
                  >
                    Clinical Studies
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/evaluation-results">
                  <a
                    className="nav-link evaluation-page-link"
                    onClick={() => handleButtonClick('item2')}
                    style={{
                      fontWeight: selectedItem === 'item2' ? 'bold' : '300',
                      textDecoration: selectedItem === 'item2' ? 'underline' : 'none',
                      textUnderlineOffset: '4px',
                      width: '190px'
                    }}
                  >
                    Evaluation Studies
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/evaluation-demo-results">
                  <a
                    className="nav-link demo-page-link"
                    onClick={() => handleButtonClick('item4')}
                    style={{
                      fontWeight: selectedItem === 'item4' ? 'bold' : '300',
                      textDecoration: selectedItem === 'item4' ? 'underline' : 'none',
                      textUnderlineOffset: '4px',
                      width: '150px'
                    }}
                  >
                    Demo Studies
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/submit-evaluation">
                  <a
                    className="nav-link upload-page-link"
                    onClick={() => handleButtonClick('item3')}
                    style={{
                      fontWeight: selectedItem === 'item3' ? 'bold' : '300',
                      textDecoration: selectedItem === 'item3' ? 'underline' : 'none',
                      textUnderlineOffset: '4px',
                      width: '190px'
                    }}
                  >
                    Upload Evaluation
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link logout"
                  onClick={() =>
                    msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' })
                  }
                >
                  Log Out
                </a>
              </li>
              </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}
