// This component renders the page selector at the bottom of a results page
const RenderPagination = ({ state, setState, loadAnalyses }) => {
  if (state.isLoading || !state.results || !state.results.length) return null

  const changePage = async (newPage) => {
    await setState({
      ...state,
      results: [],
      page: newPage
    })
    loadAnalyses()
  }

  const PaginationItem = ({ page, children, className = '' }) => (
      <li className={`page-item ${className}`}>
        <a className="page-link" href="#" onClick={() => changePage(page)}>{children}</a>
      </li>
  )

  const totalPages = state.pagination.total_results / state.pagination.per_page

  return (
      <div className="container">
        <div className="row">
          <div className="col py-3">
            <nav aria-label="Report Page navigation">
              <ul className="pagination">
                {state.pagination.page !== 1 &&
                  <>
                    <PaginationItem page={state.pagination.page - 1}>&laquo;</PaginationItem>
                    <PaginationItem page={state.pagination.page - 1}>{state.pagination.page - 1}</PaginationItem>
                  </>
                }
                <PaginationItem className="active" page={state.pagination.page}>{state.pagination.page}</PaginationItem>
                {state.pagination.page < totalPages &&
                  <>
                    <PaginationItem page={state.pagination.page + 1}>{state.pagination.page + 1}</PaginationItem>
                    <PaginationItem page={state.pagination.page + 1}>&raquo;</PaginationItem>
                  </>
                }
              </ul>
            </nav>
            <p className="float-right">
              Showing {state.pagination.results_start} - {state.pagination.results_end} of {state.pagination.total_results} results.
            </p>
          </div>
        </div>
      </div>
  )
}

export default RenderPagination
