import PropTypes from 'prop-types'
import { ReactComponent as RefreshIcon } from './refresh-svgrepo-com.svg'

const CustomRefreshButton = ({ handleRefresh, className }) => {
  return (
      <i
        onClick={handleRefresh}
        className={className}>
        <div className="refresh-button-container">
          <RefreshIcon width="16" height="16" fill="currentColor" />
        </div>
      </i>
  )
}
CustomRefreshButton.propTypes = {
  handleRefresh: PropTypes.any.isRequired,
  className: PropTypes.string
}

export default CustomRefreshButton
